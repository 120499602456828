import React from "react";
import { Col, Row } from "react-bootstrap";
import ContactFooter from "./components/ContactFooter";
import ProductColumns from "./components/ProductColumns";

function App() {
  return (
    <div id="app">
      <div className="header">
        <div>
          <h1>Firewood County</h1>
        </div>
        <div className="contact-info">(262) 696-9652</div>
      </div>
      <div className="banner"></div>
      <Row className="catch-phrase p-3">
        <Col>
          <div className="text-center">
            <h3>We sell and deliver seasoned firewood to your door.</h3>
            <p>
              Serving southeastern Wisconsin. Most orders are filled within 3
              business days!
            </p>
          </div>
        </Col>
      </Row>
      <ProductColumns />
      <ContactFooter />
    </div>
  );
}

export default App;
