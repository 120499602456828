import React from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import faceCord from "../images/facecord.jpg";
import fullCord from "../images/fullcord.jpg";
import halfCord from "../images/halfcord_transparentBg.png";

function ProductColumns() {
  return (
    <Container className="product-columns">
      <Row>
        <Col md={4}>
          <div className="product-column">
            <Row>
              <Col className="product-image-col">
                <Image className="product-image" src={faceCord} />
              </Col>
            </Row>
            <Row>
              <Col className="text-center">
                <h3>Face Cord</h3>
                <p>Seasoned Mixed Hardwood</p>
                <p>1.5' x 4' x 8'</p>
                <span className="price">$125</span>
              </Col>
            </Row>
          </div>
        </Col>
        <Col md={4}>
          <div className="product-column">
            <Row>
              <Col className="product-image-col">
                <Image className="product-image" src={fullCord} />
              </Col>
            </Row>
            <Row>
              <Col className="text-center">
                <h3>Full Cord</h3>
                <p>Seasoned Mixed Hardwood</p>
                <p>4' x 4' x 8'</p>
                <span className="price">$350</span>
              </Col>
            </Row>
          </div>
        </Col>
        <Col md={4}>
          <div className="product-column">
            <Row>
              <Col className="product-image-col">
                <Image className="product-image" src={halfCord} />
              </Col>
            </Row>
            <Row>
              <Col className="text-center">
                <h3>Half Cord</h3>
                <p>Seasoned Mixed Hardwood</p>
                <p>4' x 4' x 4'</p>
                <span className="price">$195</span>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default ProductColumns;
