import { useState } from "react";
import {
  Button,
  Col,
  Collapse,
  Container,
  FloatingLabel,
  Form,
  Row,
} from "react-bootstrap";

const ContactFooter = () => {
  const [ordered, setOrdered] = useState(false);

  return (
    <Container fluid className="contact-footer">
      <Collapse in={!ordered}>
        <Row>
          <Col lg></Col>
          <Col lg>
            <Row>
              <Col>
                <div className="text-center">
                  <h3>Order From Us!</h3>
                  <p>
                    Just let us know who you are and what you are looking for,
                    and we will contact you to setup delivery.
                  </p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <FloatingLabel label="First Name" className="mb-3">
                  <Form.Control type="text" placeholder="First Name" />
                </FloatingLabel>
              </Col>
              <Col>
                <FloatingLabel label="Last Name" className="mb-3">
                  <Form.Control type="text" placeholder="Last Name" />
                </FloatingLabel>
              </Col>
            </Row>
            <Row>
              <Col>
                <FloatingLabel label="Email address" className="mb-3">
                  <Form.Control type="email" placeholder="name@example.com" />
                </FloatingLabel>
              </Col>
            </Row>
            <Row>
              <Col>
                <FloatingLabel label="Phone Number" className="mb-3">
                  <Form.Control type="text" placeholder="Phone Number" />
                </FloatingLabel>
              </Col>
            </Row>
            <Row>
              <Col>
                <FloatingLabel
                  label="Tell us what you are looking for."
                  className="mb-3"
                >
                  <Form.Control
                    id="order-detail"
                    as="textarea"
                    placeholder="Tell us what you are looking for."
                  />
                </FloatingLabel>
              </Col>
            </Row>
            <Row>
              <Col className="text-center">
                <Button onClick={() => setOrdered(!ordered)}>Submit</Button>
              </Col>
            </Row>
          </Col>

          <Col lg></Col>
        </Row>
      </Collapse>

      <Collapse in={ordered}>
        <Row>
          <Col className="text-center">
            <h3>Thank you!</h3>
            <p>We will be reaching out shortly to complete your order.</p>
          </Col>
        </Row>
      </Collapse>
    </Container>
  );
};

export default ContactFooter;
